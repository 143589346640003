.footer {
    background-color: #003366;
    color: white;
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 1em;
  }
  
  .footer-links {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    font-size: 0.9em;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }


  .footer-links-mobile {
    display: none;
  }
  
  .footer-link-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .footer-link-group a {
    color: white;
    text-decoration: none;
    font-size: 0.9em;
  }
  
  .footer-link-group a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .footer-links {
      display: none;
    }

    .footer-links-mobile {
        display: flex;
        gap: 40px;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 10px;
      }
  
    .footer p {
      font-size: 0.9em;
    }
  
    .footer-links a {
      font-size: 0.8em;
    }
  }
  