/* AboutUs.css */
.about-us {
    text-align: center;
    padding: 20px;
  }
  
  .about-us h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .about-us p {
    font-size: 1.2em;
    margin-bottom: 40px;
    line-height: 1.5;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .stat-item {
    background-color: #2b3e50;
    color: white;
    padding: 20px;
    flex: 1;
    margin: 10px;
    min-width: 100px;
    text-align: center;
  }
  
  .contact-us {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .map {
    width: 100%;
    max-width: 600px;
    height: 450px;
    margin-bottom: 20px;
  }
  
  .download-brochure {
    background-color: #2b3e50;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .download-brochure:hover {
    background-color: #1f2c3b;
  }
  
  @media (max-width: 768px) {
    .about-us h2 {
      font-size: 1.5em;
    }
  
    .about-us p {
      font-size: 1em;
    }
  
    .stat-item {
      font-size: 0.9em;
      padding: 15px;
    }
  
    .map {
      height: 300px;
    }
  
    .download-brochure {
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .about-us h2 {
      font-size: 1.2em;
    }
  
    .about-us p {
      font-size: 0.9em;
    }
  
    .stat-item {
      font-size: 0.8em;
      padding: 10px;
    }
  
    .map {
      height: 250px;
    }
  
    .download-brochure {
      padding: 8px 16px;
    }
  }
  