.gallery {
  text-align: center;
  /* padding: 20px; */
  /* background-color: #007bff; */
}

.gallery-heading {
  font-size: 24px;
  margin-bottom: 10px;
}



.section-buttons {
  margin-bottom: 20px;
}

.section-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.section-button.active {
  background-color: #007bff;
  color: #fff;
}

.image-slider {
  width: 100%;
}

.image-group {
  display: flex;
  justify-content: center;
}

.image-slide {
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
}

.image-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Media Queries */
@media (max-width: 768px) {
  .image-group {
    flex-direction: row;
  }

  .image-slide {
    width: 100%;
  }

  .section-button {
    margin: 0 5px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .image-group {
    flex-direction: row;
  }

  .image-slide {
    width: 50%;
  }
}

@media (min-width: 1201px) {
  .image-group {
    flex-direction: row;
  }

  .image-slide {
    width: 33.33%;
  }
}
