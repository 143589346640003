.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100vh; /* Set the height of the section */
}

.contact-section {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-section.with-background {
  background-color: cadetblue;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.input-container {
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: brown;
}

.icon {
  margin-right: 10px;
  color: #ffffff; /* Color for icons */
  margin: 10px;
}

.input-field {
  flex-grow: 1;
  width: 70%;
  padding: 10px;
  transition: border-color 0.3s ease-in-out;
}

.input-field:focus {
  border-color: #007bff; /* Highlight border color on focus */
}

.submit-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #0056b3; /* Darker color on hover */
}

.error-message {
  color: yellow;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .contact {
    flex-direction: row;
  }

  .contact-section {
    width: 50%;
    height: 100%;
  }

  .contact-form {
    width: 50%;
  }
}
