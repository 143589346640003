.project-configuration {
    text-align: center;
    padding: 50px 20px;
    background-color: #8ac5f6;
  }
  
  .project-configuration-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .project-configuration-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
  }
  
  .tower-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-bottom: 30px; */
  }
  
  .tower-button {
    background-color: #003366;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .tower-button.active {
    background-color: #0055aa;
  }
  
  .tower-button:hover {
    background-color: #0055aa;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .configuration-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .configuration-table th, .configuration-table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .configuration-table th {
    background-color: #003366;
    color: white;
    text-transform: uppercase;
  }
  
  .configuration-table td a {
    color: #003366;
    text-decoration: none;
    font-weight: bold;
  }
  
  .configuration-table td a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .project-configuration-title {
      font-size: 1.5em;
    }
  
    .tower-button {
      padding: 10px 15px;
      font-size: 0.9em;
    }
  
    .configuration-table th, .configuration-table td {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .tower-button {
      padding: 8px 12px;
      font-size: 0.8em;
    }
  
    .configuration-table th, .configuration-table td {
      padding: 8px;
    }
  }
  