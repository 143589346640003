/* .carousel .slide {
    background: none;
  }
  
  .carousel .legend {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
  } */


  .carousel-booking-container {
    height: 100%; /* Set the height of the container */
  }


  .carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
  }
  
  .carousel img {
    width: 100%;
    height: auto;
    /* border-radius: 10px; */
  }
  
  .carousel .control-arrow {
    background: rgba(0, 0, 0, 0.5);
    /* border-radius: 50%; */
  }

  .bookingopensection{
    /* style={{zIndex:'10', top:'65px', position:'absolute',left:'20px'}}, */
    z-index: 10;
    top: 65px;
    position: absolute;
    left: 20px;
    
  }


  @media (max-width: 768px) {
   .bookingopensection{
    display: none;
   }
  }
  




  