/* App.css */

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* #gallery{
  background-color: aqua;
  margin-top: 0px;
} */

/* #virtual-tour{
  background-color: rgb(255, 0, 128);
  margin-top: 0px;
  height: fit-content;
} */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

#header {
  width: 100%;
  position: fixed;
  top: 80;
  left: 0;
  z-index: 1000;
}
#bookingopen{
  /* z-index: 1005;
  position: fixed; */
  display: none;
  /* top: 0; */
}

#carousel, #masterpiece, #configuration, #gallery, #virtual-tour, #floor-plan, #about, #contact {
  width: 100%;
  /* padding-top: 60px;
  margin-top: 20px; */
}

@media (max-width: 768px) {
  #carousel, #masterpiece, #configuration, #gallery, #virtual-tour, #floor-plan, #about, #contact {
    padding-top: 50px; /* Adjust for mobile */
  }

  #bookingopen{
    display: flex;
   
  }
  
}

/* Add any other custom styles as needed */


/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  position: relative;
  overflow: scroll;
  height: 80%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
