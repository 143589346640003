/* FloorPlan.css */

.floor-plan-container {
  text-align: center;
  padding: 20px;
}

.master-plan {
  position: relative;
  margin-bottom: 20px;
}

.master-plan-image {
  width: 100%;
  max-width: 800px;
  height: auto;
}

.view-master-plan-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2b3e50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.view-master-plan-button:hover {
  background-color: #1b2a38;
  transform: translate(-50%, -50%) scale(1.05);
}

h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.floor-plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.floor-plan-item {
  position: relative;
  width: 300px;
  max-width: 100%;
  text-align: center;
  overflow: hidden; /* Ensure images stay within bounds on hover */
}

.floor-plan-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  filter: blur(5px); /* Add blur by default */
}

.floor-plan-image:hover {
  transform: scale(1.05);
}

.floor-plan-button {
  background-color: #0055aa;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.floor-plan-button:hover {
  background-color: #003d80;
}

@media (max-width: 768px) {
  .master-plan-image {
    width: 100%;
  }

  .view-master-plan-button {
    padding: 8px 16px;
  }

  h3 {
    font-size: 1.5em;
  }

  .floor-plan-item {
    width: 100%;
    max-width: 300px;
  }

  .floor-plan-button {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .view-master-plan-button {
    padding: 6px 12px;
  }

  h3 {
    font-size: 1.2em;
  }

  .floor-plan-button {
    padding: 6px;
  }
}
