.booking-open {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 600px;
    margin: 20px auto;
  }
  
  .booking-open h1 {
    color: #ff5733;
  }
  
  .booking-open h2 {
    color: #333;
  }
  
  .booking-open p {
    margin: 10px 0;
    color: #666;
  }
  
  .details {
    background-color: #ffe0b2; /* Matching the color provided in the image */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-top: 20px;
    position: relative;
    
  }

  .details-item-border{
    /* animation: pop-up 3s infinite; */
    border: 2px dotted #ff5733; 
    padding: 5px;
    animation: pop-up 10s infinite;
  }
  
  .details-item {
    margin: 10px 0;
    font-size: 25px;
    font-weight: bold;
    animation: pop-up2 3s infinite;
    /* animation-delay: 10s; */
    color: #ff5733;
  }
  
  @keyframes pop-up2 {
    0%,100%{
      opacity: 1;
      transform: scale(0.92);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }


  /* @keyframes pop-up {
    0%, 20%, 100% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.1);
    }
  } */

  @keyframes pop-up {
    0%, 20%, 90%, 100% {
        opacity: 1;
      transform: scale(1);
    }
    10%, 95% {
      transform: scale(1.1);
      opacity: 0;
    }
  }
  
  .booking-open h3 {
    margin-top: 20px;
    color: #00796b;
  }
  
  .booking-open h4 {
    margin-top: 10px;
    color: #d32f2f;
  }
  
  .enquire-button {
    background-color: #ff9800;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  