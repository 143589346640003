/* animations.css */
@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  @keyframes scalePulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .blinking {
    animation: blink 1s infinite;
  }
  
  .scale-pulse {
    animation: scalePulse 1.5s infinite;
  }
  