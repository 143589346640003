.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  top: 0; /* Stay at the top */
}

.left-logo img, .right-logo img {
  height: 50px; /* Adjust according to your logo size */
}

.left-logo img {
  margin-left: 30px;
}

.right-logo img {
  margin-right: 30px;
}

.menu {
  display: flex;
}

.menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  margin: 0 10px;
}

.menu ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle-container {
  display: none;
  align-items: center;
}

.menu-divider {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle-container {
    display: flex;
    align-items: center;
  }

  .right-logo-small {
    display: flex;
    height: 50px;
  }

  .left-logo img {
    margin-left: 0;
  }

  .right-logo {
    display: none;
  }

  .right-logo img {
    margin-right: 0;
  }

  .menu-divider {
    width: 100%; /* Full width */
    margin: 5px 0; /* Adjust spacing */
    border: none;
    border-top: 1px solid #000; /* Border style */
    display: block; /* Ensure it is displayed */
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .menu.open {
    display: flex;
    opacity: 1;
  }

  .menu ul {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
    transition: transform 0.3s ease-in-out; /* Slide-in animation */
    transform: translateY(-20px);
  }

  .menu.open ul {
    transform: translateY(0); /* Slide in when menu opens */
  }

  .menu ul li {
    margin: 10px 0;
    opacity: 0; /* Initially invisible */
    animation: fadeInUp 0.5s ease-in-out forwards;
  }

  .menu-toggle {
    display: block;
  }

  .menu.open ul li {
    opacity: 1; /* Fade in when menu opens */
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
