.virtual-tour-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    background-color: cadetblue;
  }
  
  .image-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .video-container {
    width: 100%;
    height: 500px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .image-container img {
    width: 95%;
    height: 100%;
    border-radius: 5px;
  }
  
  .video-container iframe {
    width: 95%;
    height: 100%;
    border-radius: 5px;
  }
  
  @media (min-width: 768px) {
    .virtual-tour-section {
      flex-direction: row;
      width: 100%;
      height: auto;
    }
  
    .image-container {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 5px;
      object-fit: cover;
    }
  
    .video-container {
      width: 50%;
      display: flex;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      position: relative;
      object-fit: contain;
    }
  }
  