.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-content {
    /* position: relative; */
    display: flex;
    background-color: #fff;
    /* padding: 20px; */
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center the content */
  }
  
  .close-button {
    /* position: absolute; */
    /* top: 10px;
    right: 10px; */
    /* padding: 5px; */
    border: none;
    /* padding: 20px; */
    /* background-color: transparent; */
    color: #000;
    cursor: pointer;
    /* font-size: 20px; */
  } 
  
   .close-button:hover {
    color: #007bff;
  }
  
  .contact-form-container {
    margin: 20px; /* Adjust the margin as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  