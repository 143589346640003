/* Floaction.css */
.floaction {
    position: fixed;
    bottom: 30%;
    right: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    margin: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color:#007bff;
    z-index: 1000;
  }
  
  .floaction-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    font-size: 24px;
  }
  
  .floaction-button:hover {
    transform: scale(1.1);
  }
  
  .floaction-button.call {
    color: #056d2b; /* WhatsApp green */
  }
  
  .floaction-button.email {
    color: #d44638; /* Gmail red */
  }
  
  .floaction-button.whatsapp {
    color: #056d2b; /* WhatsApp green */
  }

  .whatsappp{
    color: #056d2b; 
  }

  .floaction-button h1{
    display: none;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .floaction {
      flex-direction: row;
      width: 100%;
      justify-content:space-around;
      bottom: 0px;
      right: 50%;
      /* background-color:#007bff; */
      background-color:#fff;
      transform: translateX(50%);
      /* padding: 10px; */
      /* gap: 5px; */
      border-top: 1px solid lightslategray;
      z-index: 1000;
      
    }
  
    .floaction-button {
      width: 43%;
      font-size: 20px;
      /* border-radius: 30px; */
      /* padding: 5px; */
      gap: 0px;
      box-shadow: none;
      background-color:transparent;
      /* border-right: 2px solid black; */

    }

    .floaction-button h1{
      display: flex;
      font-size: 15px;
      margin-left: 4px;
    }

    .title{
      font-size: 10px;
    }

    .floaction-button.call {
        margin-left: 3px;
        margin-right: 10px;
        text-decoration: none;
        
      }

      .floaction-button.email {
        color: #d44638; /* Gmail red */
        text-decoration: none;
        
      
      }
      
      .floaction-button.whatsapp {
        margin-right: 3px;
        text-decoration: none;
        
      }

  }
  