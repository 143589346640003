.masterpiece {
    padding: 0px 10px;
    background-color: #fff;
    
  }


  .masterpiece-heading{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .masterpiece-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .masterpiece-text {
    flex: 1 1 45%;
    max-width: 600px;
    padding: 20px;
  }
  
  .masterpiece-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .masterpiece-description {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
  }
  
  .masterpiece-image {
    flex: 1 1 45%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
  }
  
  .masterpiece-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }



  .animated-line-container {
    width: 30%;
    height: 2px;
    background-color: #333;
    position: relative;
    display: flex;
    justify-content: center; /* Center the line horizontally */
    margin-bottom: 20px; /* Optional: Add margin at the bottom */
  }
  
  .animated-line {
    width: 30%; /* Set the width to 50% */
    height: 100%;
    background-color: #ff4500; /* Adjust color as needed */
    position: absolute;
    top: 0;
    animation: grow 2s ease-in-out infinite alternate;
  }
  
  @media (max-width: 768px) {
    .masterpiece-container {
      flex-direction: column;
    }

    .masterpiece-title {
        font-size: 1.5em;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
      }
  
    .masterpiece-text,
    .masterpiece-image {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 10px;
    }
  }

  @keyframes grow {
    from {
      width: 0;
      left: 50%; /* Center the animation starting point */
      transform: translateX(-50%); /* Move it back to center */
    }
    to {
      width: 100%;
      left: 0; /* Move to the left edge of the container */
      transform: translateX(0);
    }
  }